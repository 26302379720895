import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Parse } from "parse";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{
  title = '509Expedited';

  contactForm: FormGroup;
  submitted = false;

  constructor(private formBuilder: FormBuilder) {
  }

  ngOnInit(): void {
      this.contactForm = this.formBuilder.group({
        fullName: ['', Validators.required],
        phoneNumber: ['', Validators.required],
        service: ['Dispatching', Validators.required],
        email: ['', [Validators.required, Validators.email]],
        message: ['', Validators.maxLength(250)]
      });
  }

  get f() { return this.contactForm.controls}

  async onSubmit() {
    this.submitted = true;
    if(this.contactForm.invalid) {
      return;
    }

    let Applicant = Parse.Object.extend("Applicant");
    let newApplicant = new Applicant();
    newApplicant.set("FullName", this.f.fullName.value);
    newApplicant.set("PhoneNumber", this.f.phoneNumber.value);
    newApplicant.set("Email", this.f.email.value);
    newApplicant.set("Service", this.f.service.value);
    newApplicant.set("Message", this.f.message.value);

    try{
      await newApplicant.save();
      alert("Your application was sent, we will contact you as soon as possible");
    }catch(error) {
      alert("Error: " + error.message);
      console.log(error);
      return;
    }
    this.contactForm.reset();
    for(var name in this.f) {
        this.f[name].setValue('');
        this.f[name].setErrors(null);
    }
  }
}
